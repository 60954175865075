<template>
  <div class="home" style="min-height:85vh;overflow-x: hidden;background-color:#FAFFFF;">
    
    <div class="bestOfferDiv">
      <div class="bestOfferDetail">
        <div class="homeBestOfferDetailDiv">
          <a-list v-if="!loadingBestOffers && bestList.length > 0" item-layout="horizontal" :data-source="bestList" :split=false>
            <a-list-item slot="renderItem" slot-scope="item">
                <view-item class="homeBestOfferDetailItemDiv" :cellData="item" >
                </view-item>
            </a-list-item>
          </a-list>
          <div v-else-if="!loadingBestOffers && bestList.length <= 0">  
            
            <div style="text-align: center;margin-top: 50px;">
              <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fillRule="evenodd"><ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse><g fillRule="nonzero" stroke="#D9D9D9"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path></g></g></svg>
            </div>
            <div class="resortEmptyDiv">
              Apologies as we do not have a property that matches your criteria.
              Please select another option or contact our Concierge via chat to assist you further.
            </div>
          </div>
          <div v-else class="homeLoadingDiv">
            <img class="homeLoadingGifDiv"
            :src="'../images/logo.gif'" alt="" width="100px" height="100px" loop=infinite>
          </div>
        </div>
      </div>
    </div>


    <contactus-viewer></contactus-viewer>
    <instagram-viewer style="padding-bottom:20px;"></instagram-viewer>    

  </div>
</template>

<script>
import ViewItem from './ListItems/home-offer-item.vue'
import { api } from '../helpers/Helpers';
import moment from 'moment';

import ContactusViewer from './ListItems/contactus-viewer.vue';
import InstagramViewer from './ListItems/instagram-viewer.vue';
const bestList = [
];
export default {
  name: 'Home',
  
  metaInfo: {
    title: 'VUE Collection',
    titleTemplate: '%s',
    meta: [
      {name: 'description', content: 'Explore the best luxury travel deals with VUE Collection Collection. Uncover exclusive discounts and special packages for top-tier resorts in the Collection. Start your dream vacation today with our limited-time offers.'},

    ], 
    link: [
      {rel: 'canonical', href: 'https://vuecollection.com/bestoffers'}
    ]
  },

  data() {
    return {
        bestList,
                
        loadingBestOffers:false
    };
  },
  components: {
    ViewItem,
    ContactusViewer,
    InstagramViewer
},
  methods: {
      moment,
      
      getBestHotels:async function() {
        this.loadingBestOffers = true;
        const res = await api.getBestHotelInfoAll();
        this.getBestHotelsResult(res);
        this.loadingBestOffers = false;
      },
      getBestHotelsResult(res){
          let bestListDetail = res.hotelInfo;
          this.bestList = res.bestInfo;
          
          this.bestList.sort(function(a,b) {
              let aValue = a['shownewprice'];
              let bValue = b['shownewprice'];
              return (aValue > bValue) ? 1 : ((bValue > aValue) ? -1 : 0);
            } 
          );
          for(let i = 0; i < this.bestList.length; i++){
            const _info = bestListDetail.filter(obj => obj._id == this.bestList[i].hotelid);
            // let _temp = this.bestList[i];
            if (_info.length > 0)
              this.bestList[i]['info'] = _info[0];
          }
      },
  },
  mounted(){
      this.selectedStartDate = new Date();
      this.getBestHotels();
  }
}
</script>

<style>
.front{
  width:100%;
  height: 100%;
}
.back{
  width:100%;
  position: relative;
}
</style>

<style>
  
    .instagramVideo{
        height: 100%;
        width: 100%;
        cursor: pointer;
        object-fit: cover;
        opacity: 1;
        border-radius: 6px;
    }
    .instagramVideo:hover {
        opacity: 0.9;
    }
    .instagramImageDiv {
      position:relative;
      border-radius: 6px;
      overflow: hidden;
      max-height: 38vh;
      height:35vh;
      margin-bottom:0;
      margin-top: auto;
      transform-origin:bottom center;
      display: inline-grid;

    }
    .instagramImageDiv:hover {
        /* transform: scaleY(1); */
      max-height: 38vh;
      height:38vh;
    }
    .instagramImage {
      height: 100%;
      width: 100%;
      cursor: pointer;
      opacity: 1;
      border-radius: 6px;
      /* overflow: hidden; */
    }

    .instagramImage:hover {
        opacity: 0.9;
    }
    .ant-row {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .homeViewBtn{
      font-family: Montserrat light;
      background-color: var(--primary-sub-title-color);
      width:100px;
      height:35px;
      color:white;
      cursor:pointer;
      text-align:center;
      border-color: transparent;
      margin-left:10px;
      margin-right:auto;
      font-weight: 500;
      font-size: 14px;
      line-height: 35px;
    }
    .homeViewBtn:hover{
      background-color: #ada39a;
    }
    .slideHomeMain{
        display: block;
        width:100%;
        height:90vh;
        object-fit: cover;
        min-height:150px;
    }
</style>